import { TenantUser, UserRoleDefinition } from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component({})
export default class UserList extends Vue {
  @Getter('orderedTenantUserRoles', { namespace: Namespace.App })
  public orderedTenantUserRoles!: UserRoleDefinition[]

  public rolesListingMode: any = {
    ADDING_USER: 1,
    EDITING_USER: 2,
    LIST_USERS: 3,
  }

  protected created() {
    Object.freeze(this.rolesListingMode)
  }

  private isJigSpaceStaff(tenantUser: TenantUser): boolean {
    return tenantUser.tenantId === TenantHelpers.GetJigSpaceStaffTenantID()
  }

  public GetRoleDefsForUserAndAction(
    roleMode: number,
    tenantUser?: TenantUser,
    cannotDowngradeAdminUser: boolean = true,
    cannotSelectTeamAdminRole: boolean = true
  ): UserRoleDefinition[] {
    let isStaff = false
    let guestViewerRoleAllowed = roleMode === this.rolesListingMode.LIST_USERS

    if (tenantUser != null) {
      isStaff = this.isJigSpaceStaff(tenantUser)
      // Don't allow adding guest user - this is done through a jig invite.
      // When editing a user, if their role was originally a guest viewer, we allow them to still be a guest viewer.
      // But we disallow users downgrading to a guest viewer from a paid user.
      if (
        roleMode === this.rolesListingMode.EDITING_USER &&
        tenantUser.roles.length > 0 &&
        tenantUser.roles[0] === TenantHelpers.roleGuestViewer1
      ) {
        guestViewerRoleAllowed = true
      }
    }

    let roles = []
    for (const roleItem of this.orderedTenantUserRoles) {
      const r = AppHelper.deepCopyNestedJsonSafeObject(roleItem)
      // Do not display `guest` role
      if (r.Name === TenantHelpers.roleGuestViewer1 && !guestViewerRoleAllowed) {
        continue
      }

      // When listing users we only want to display the roles the user has
      if (tenantUser != null && roleMode === this.rolesListingMode.LIST_USERS && tenantUser.roles.indexOf(r.Name) === -1) {
        continue
      }

      // When adding users or edit a user,
      // `Team Admin` role is only available to be selected/changed as a dropdown field option
      // if user is a TeamAdmin or SuperUser.
      // Or if no Team Admin exists in Tenant, a Team Manager can upgrade self to be Team Admin
      r.disabled = false
      if (r.Name === TenantHelpers.roleTeamAdmin1 && cannotSelectTeamAdminRole) {
        r.disabled = true
      }

      // the last `TeamManager` cannot be downgraded.
      if (r.Name === TenantHelpers.rolePresenter1 && cannotDowngradeAdminUser) {
        r.disabled = true
      }

      if (
        !isStaff &&
        r.Name !== TenantHelpers.roleGuestViewer1 &&
        r.RestrictToTenantIds.indexOf(TenantHelpers.GetJigSpaceStaffTenantID()) !== -1
      ) {
        continue
      }

      roles.push(r)
    }

    return roles
  }
}
