import { LibraryCategory } from '@/store/modules/teamlibrary/types'
import { Filters } from '@/utils/filters'
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class FiltersMixin extends Vue {
  /* eslint-disable-next-line lines-around-comment */
  /**
   * Checks if the text content of a referenced DOM element is overflowing its visible area. Useful for conditionally displaying tooltip when the text overflows a container.
   *
   * @param {string} refKey - The reference key for the Vue component's `$refs` object, pointing to the target DOM element.
   * @returns {boolean} - Returns `true` if the text is overflowing, `false` otherwise.
   *
   * **Key variables**:
   * - `scrollWidth`: The full width of the content, including overflowed content not visible within the viewport.
   * - `clientWidth`: The width of the element's visible content area, excluding scrollbars.
   * - If `scrollWidth` is greater than `clientWidth`, the content is overflowing and the function returns `true`.
   *
   */
  public isTextOverflowed(refKey: string) {
    if (this.$refs[refKey] == null) {
      return true
    }

    const element = this.$refs[refKey] as Element

    return element.scrollWidth > element.clientWidth
  }

  public formatDate(value: string, formatOption: Intl.DateTimeFormatOptions = { dateStyle: 'short', timeStyle: 'short' }): string {
    return Filters.formatDate(value, formatOption)
  }

  public thumbnailURLMap(value: string) {
    return Filters.thumbnailURLMap(value)
  }

  public thumbnailURLMapCustomType(value: string, fileType: string = 'png') {
    return Filters.thumbnailURLMapCustomType(value, fileType)
  }

  public hasThumbnailPlaceHolder(value: string): boolean {
    return Filters.hasThumbnailPlaceHolder(value)
  }

  public arrayToCsvString(values: string[], delimiter: string = ', '): string {
    return Filters.arrayToCsvString(values, delimiter)
  }

  public categoryIndiciesToCsvString(categoryIndicies: number[], categoriesMap: any): string {
    return Filters.categoryIndiciesToCsvString(categoryIndicies, categoriesMap)
  }

  public categoriesArrayToMap(categories: LibraryCategory[]): Map<number, LibraryCategory> {
    return Filters.categoriesArrayToMap(categories)
  }
}
