import { JigMetadata, JigShareRequest, SharedJigItem } from '@/store/modules/jig/types'
import { Namespace } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

declare var consoleLog: any

@Component({})
export default class JigSharelink extends Vue {
  @State('jigMetadata', { namespace: Namespace.Jig })
  public jigMetadata!: JigMetadata

  @Action('requestJigDeeplinkURL', { namespace: Namespace.Jig })
  public requestJigDeeplinkURL: any

  public isClipboardCopySuccess: boolean = false
  public clipboardSnackbarVisible: boolean = false
  public latestClipboardEntry: string = ''
  public latestClipboardTitle: string = 'Link copied to clipboard!'

  public get jigDeeplinkURL(): string {
    let deeplinkVal: string = ''

    if (this.jigMetadata.DeeplinkURL && this.jigMetadata.DeeplinkURL !== '') {
      deeplinkVal = this.jigMetadata.DeeplinkURL
    }

    return deeplinkVal
  }

  public stringNilOrEmpty(v: string): boolean {
    return v === undefined || v === null || v.length === 0
  }

  public async copyShareableLink(v: string, showSuccessMessage: boolean = true, activity?: string, event?: Event) {
    if (!this.stringNilOrEmpty(v) && !!this.jigDeeplinkURL) {
      await this.writeToClipboard(v, showSuccessMessage, activity, event)
    } else {
      await this.requestJigDeeplinkUrl(showSuccessMessage)
    }
  }

  public copyJigDeeplinkUrlToClipboard(showSuccessMessage: boolean = true, activity?: string) {
    if (!!this.jigDeeplinkURL) {
      this.writeToClipboard(this.jigDeeplinkURL, showSuccessMessage, activity)

      return true
    }

    return false
  }

  public async requestJigDeeplinkUrl(showSuccessMessage: boolean = true) {
    let linkRequireResult = this.copyJigDeeplinkUrlToClipboard(showSuccessMessage)

    if (linkRequireResult) {
      return linkRequireResult
    }

    const req: JigShareRequest = {
      jig: this.jigMetadata,
      branchIOCustomData: {},
      targetApp: 'jigspace',
      route: 'JigDetails',
    }

    await this.requestJigDeeplinkURL(req)
    // Note: share links can be debugged by having a branch io account and adding ?debug=true
    // to the end of the URL
    // Also Branch IO responses may be intercepted by a browser ad blocker.

    if (this.jigDeeplinkURL === undefined || this.jigDeeplinkURL === '') {
      linkRequireResult = false as boolean
      consoleLog('The result Jig Share link was empty, something went wrong getting a share link.')
    } else {
      linkRequireResult = true as boolean
      this.copyJigDeeplinkUrlToClipboard(showSuccessMessage)
    }

    return linkRequireResult
  }

  public async writeToClipboard(v: string, showSuccessMessage: boolean = true, activity?: string, event?: Event) {
    if (v.length > 0) {
      window.navigator.clipboard.writeText(v).then(
        () => {
          // clipboard write succeeded
          if (event && event.target && (event.target as HTMLInputElement).type === 'text') {
            const target = event.target as HTMLInputElement
            target.select()
          }
          this.isClipboardCopySuccess = true
          this.latestClipboardEntry = v
          this.clipboardSnackbarVisible = showSuccessMessage
          this.emitSuccess(activity)
        },
        () => {
          // clipboard write failed
          this.isClipboardCopySuccess = false
          this.latestClipboardEntry = v
          this.clipboardSnackbarVisible = false
        }
      )
    }
  }

  public vClipboardResultHandler({ value, event, activity }: any, isSuccess: boolean, showSuccessMessage: boolean = true) {
    if (event && event.target && event.target.type === 'text') {
      if (!!value) {
        // Select text field content to show content is selected and copied
        event.target.select()
      } else {
        // As we cannot disable v-clipboard function, so manually checking if text field is empty (which means deeplink is empty) when it's clicked. If there's no deeplink do not show success message.
        isSuccess = false
        showSuccessMessage = false
      }
    }

    this.isClipboardCopySuccess = isSuccess
    this.latestClipboardEntry = value
    this.clipboardSnackbarVisible = showSuccessMessage

    this.emitSuccess(activity)
  }

  public emitSuccess(activity?: string) {
    // clipboardSnackbarVisible === true means it's copying the share link
    // if it's false it's a QR code request
    this.$emit('on-sharelink-tracking', {
      event: this.clipboardSnackbarVisible ? 'JigShared' : 'QRCodeSharingQR',
      activity: activity || 'JigSharedViaShareableLink',
    })

    const payload: any = {
      isClipboardCopySuccess: this.isClipboardCopySuccess,
      latestClipboardEntry: this.latestClipboardEntry,
      latestClipboardTitle: this.latestClipboardTitle,
      clipboardSnackbarVisible: this.clipboardSnackbarVisible,
    }

    this.$emit('on-copy-succeeded', payload)
  }
}
