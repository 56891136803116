import { AppConst } from '@/constants'

// AppConst.apiDomain might be undefined (not ready) when excuted in another JS file,

// hence only export endpoint path, apiDomain should be append later
export namespace LibraryConstants {
  export enum JobsAPI {
    jobsV1Url = '/jobs/v1',
  }

  export enum JobStatus {
    NotStarted = 'not_started',
    Uploading = 'uploading',
    PipelineProcessing = 'pipeline_processing',
    ResultCreation = 'result_creation',
    Paused = 'paused',
    Failed = 'failed',
    Finished = 'finished',
  }

  export const JobStatuses: JobStatus[] = [
    JobStatus.NotStarted,
    JobStatus.Uploading,
    JobStatus.PipelineProcessing,
    JobStatus.ResultCreation,
    JobStatus.Paused,
    JobStatus.Failed,
    JobStatus.Finished,
  ]
}

function getTenantLibraryUploadingUrl(tenantId: any) {
  return `${AppConst.apiDomain}/tenants/v1/${tenantId}/library/v1/uploading/v1`
}
export { getTenantLibraryUploadingUrl }
