import { TenantConst } from '@/constants'
import { createAuth0Client } from '@auth0/auth0-spa-js'
import { EventEmitter } from 'events'
import Vue from 'vue'

let instance

export const getInstance = () => instance

/**
 *  Vue.js Instance Initialization
 */

export const useAuth0 = ({
  onRedirectCallback = () => window.history.replaceState({}, document.title, window.location.pathname),
  redirectUri = window.location.origin,
  ...pluginOptions
}) => {
  if (instance) return instance

  instance = new Vue({
    mixins: [EventEmitter.prototype],
    data() {
      return {
        auth0Client: null,
        accessToken: null,
        idToken: null,
        idTokenRaw: '',
        isAuthenticated: false,
        isLoading: true,
        tenants: [],
        uid: -1,
        user: {},
        localStorageKey: 'loggedIn',
        segmentTraitsKey: 'ajs_user_traits',
        segmentIdKey: 'ajs_user_id',
        error: null,
        appState: null,
        isReadyPromise: null, // Define the promise here
        readyResolve: null, // Will hold the resolve function
      }
    },
    async created() {
      this.isReadyPromise = new Promise((resolve) => {
        this.readyResolve = resolve
      })

      this.auth0Client = await createAuth0Client({
        ...pluginOptions,
        clientId: pluginOptions.clientId,
        domain: pluginOptions.domain,
        authorizationParams: {
          audience: pluginOptions.audience,
          scope: pluginOptions.scope,
          redirect_uri: redirectUri,
        },
      })
      try {
        const search = window.location.search

        if ((search.includes('code=') || search.includes('error=')) && search.includes('state=')) {
          const { appState } = await this.auth0Client.handleRedirectCallback()

          this.appState = appState
        }
      } catch (error) {
        this.error = error
      } finally {
        const isAuthenticated = await this.auth0Client.isAuthenticated()

        if (isAuthenticated) {
          this.idToken = await this.auth0Client.getIdTokenClaims()
          this.idTokenRaw = this.idToken ? this.idToken.__raw : ''
          // In previous implementation using auth0-js, we had manual method to check/set auth accessToken expiry and generate new one based on expiry status.
          // In auth0-spa-js, getTokenSilently returns new accessToken each time when called, it's all built in.
          // In this integration on each page load, accessToken will be a new one, we could remove all relevant legacy manual function.
          this.accessToken = await this.auth0Client.getTokenSilently()
          this.user = await this.auth0Client.getUser()
          this.uid = this.user[TenantConst.auth0ProfileKeys.uid]
          this.tenants = this.user[TenantConst.auth0ProfileKeys.tenants]
        }

        this.isAuthenticated = isAuthenticated
        this.isLoading = false

        if (this.readyResolve) {
          this.readyResolve() // Resolve the promise when Auth0 is ready
        }
      }
    },
    methods: {
      loginWithRedirect(options) {
        return this.auth0Client.loginWithRedirect(options)
      },
      logout(customReturnTo = '') {
        localStorage.removeItem(this.localStorageKey)
        this.auth0Client.logout({
          logoutParams: {
            returnTo: `${window.location.origin}${customReturnTo}`,
          },
        })

        this.clearPII()
      },
      async getAccessTokenSilently() {
        return await this.auth0Client.getTokenSilently()
      },
      // Clear Personally Identifiable Information (PII) to reduce vulnerabilities.
      clearPII() {
        if (window.analytics) {
          window.analytics.reset()
        } else {
          // If segment analytics is not loaded or not ready for any reason, manually clear.
          localStorage.removeItem(this.segmentTraitsKey)
          localStorage.setItem(this.segmentIdKey, 'null') // set to string 'null' to match with Segment reset() behaviour. Also in actual testing removeItem doesn't work on `ajs_user_id` item.
          AppHelper.deleteCookie(this.segmentIdKey)
        }

        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i)

          // Clear all the fingerprints left by tus uploader which might contain sensitive step file details
          // Successful upload fingerprints are cleared automatically, however failed upload and existing fingerprints need to be manually cleared.
          if (key.startsWith('tus::')) {
            localStorage.removeItem(key)
          }
        }
      },
      isReady() {
        return this.isReadyPromise
      },
    },
  })

  return instance
}

/**
 *  Vue.js Plugin Definition
 */

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth0 = useAuth0(options)
  },
}
