import Vue from 'vue'
// Components
import '@/components'

// Plugins
import '@/plugins'
import { Auth0Plugin } from '@/plugins/auth0'

// Sync router with store
import { sync } from 'vuex-router-sync'

import App from '@/App.vue'
import i18n from '@/i18n'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import store from '@/store'
import VueGtm from 'vue-gtm'

declare var consoleWarn: any

Vue.use(VueGtm, {
  // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  id: 'GTM-P8WSRF2',
  // queryParams: {
  //   // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x',
  // },
  defer: true, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: process.env.VUE_APP_DEV_TOOLS === 'true', // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ['callback'], // callback is ignored to avoid sending #access_token=123.456.789 in the path as that is PII
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
})

// Sync store with router
sync(store, router)

const redirectUriDomain =
  process.env.VUE_APP_DASHBOARD_ENV === 'localhost'
    ? 'http://localhost:8011'
    : `https://dashboard.${(process.env.VUE_APP_WEB_DOMAIN as string).substring(8).replace(/\/$/g, '')}`
Vue.config.productionTip = false
Vue.use(Auth0Plugin, {
  audience: process.env.VUE_APP_AUDIENCE,
  clientId: process.env.VUE_APP_CLIENT_ID,
  domain: process.env.VUE_APP_DOMAIN,
  redirectUri: `${redirectUriDomain}/callback`,
  scope: 'openid profile email',
})

Vue.prototype.$analytics = (window as any).analytics

/* eslint-disable no-console */
// Extend the Vue prototype to add $callRefMethod
// It checkes if a ref and relevant method exists. Return true if ref.method has successfully processed, otherwise return false.
// prettier-ignore
Vue.prototype.$callRefMethod = function <T extends Record<string, any>> (refName: string, methodName: keyof T, ...args: any[]): boolean {
  const ref = this.$refs[refName]
  if (Array.isArray(ref)) {
    if (ref.length === 0) {
      consoleWarn(`Ref '${refName}' is an empty array. (methodName: ${String(methodName)})`)
      return false
    }
    if (typeof ref[0][methodName] !== 'function') {
      consoleWarn(`Method '${String(methodName)}' not found on ref '${refName}'`)
      return false
    }
    ref[0][methodName](...args)
    return true
  } else if (!ref) {
    consoleWarn(`Ref '${refName}' not found. (methodName: ${String(methodName)})`)
    return false
  } else if (typeof ref[methodName] !== 'function') {
    consoleWarn(`Method '${String(methodName)}' not found on ref '${refName}'`)
    return false
  } else {
    ref[methodName](...args)
    return true
  }
}

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
