import { Namespace, StandardObject } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({})
export default class Invites extends Vue {
  @State('createBulkResponse', { namespace: Namespace.Invites })
  public createBulkResponse!: StandardObject

  public failedInvites: any[] = []

  private get createBulkResponses(): any {
    if (this.createBulkResponse != null && this.createBulkResponse.Responses != null) {
      return this.createBulkResponse.Responses
    }

    // Fallback method to make sure we always get the real store state value
    return this.$store.state.invites.createBulkResponse.Responses
  }

  // When creating more than 1 invite (bulk invites) - the API will return a 201 if 1 or more invitations got
  // created successfully and potentially errors for the other nested requests. If we find some errors, we
  // report them on the "modalMode.InviteResults" mode. Otherwise we exit the invite flow.
  public extractFailedInvites() {
    this.failedInvites = []

    if (this.createBulkResponses == null) {
      return
    }

    for (const obj of this.createBulkResponses) {
      const item = obj

      if (item.ErrorMsg.length > 0) {
        this.failedInvites.push(obj)
      }
    }
  }

  public craftFailedInvitesCopy() {
    let copy = '<p>Following invitations have failed.</p><ul>'
    this.failedInvites.forEach((item: any, index: number) => {
      copy = `${copy}<li class="invite__item">${item.Response.Invitation.inviteeEmail}: ${item.Response.Invitation.status}</li>`

      if (index === this.failedInvites.length - 1) {
        copy = `${copy}</ul>`
      }
    })

    return copy
  }
}
