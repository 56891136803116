/**
 * The JigPermissions mixin class provides reusable methods and computed properties
 * for checking user roles and permissions related to the 1:1 permission tab for Jigs.
 * It is designed for reuse across the application, ensuring consistent permission logic.
 *
 * Computed Properties:
 * - currentUser: Retrieves the currently logged-in user from the tenantUsers array
 *   based on the user's unique identifier (Uid). Returns undefined if the user is not found.
 *
 * Methods:
 * - isTeamManagerOfJigOwnerTenant: Determines if the current user is a Team Manager
 *   of the tenant that owns the Jig.
 * - isTeamEditPermissionOn: Checks if the "whole team can edit" permission is enabled for this Jig.
 */

import { JigTeamShareTracking } from '@/components/share/JigTeamShareTracking'
import { JigConst } from '@/constants'
import { Tenant, TenantToken, TenantUser } from '@/store/modules/app/types'
import { JigMetadata, JigTeamSharePermissionCallbackPayload, JigVisibilityEventData, TenantJigPermissions } from '@/store/modules/jig/types'
import { Namespace } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { isEqual } from 'lodash'
import { Component, Vue } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'

@Component({})
export default class JigPermissions extends Vue {
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('currentSignedInUser', { namespace: Namespace.App })
  public currentSignedInUser!: TenantUser | null
  @State('isSuperUser', { namespace: Namespace.App })
  public isSuperUser!: boolean
  @State('tenantUsers', { namespace: Namespace.App })
  public tenantUsers!: TenantUser[]
  @State('jigMetadata', { namespace: Namespace.Jig })
  public jigMetadata!: JigMetadata
  @State('myTenantTokens', { namespace: Namespace.App })
  public myTenantTokens!: TenantToken[]
  @State('teamSharePermissions', { namespace: Namespace.Jig })
  public teamSharePermissions!: JigConst.SharePermission[]

  @Mutation('updateTeamSharePermissions', { namespace: Namespace.Jig })
  public updateTeamSharePermissions: any

  public myTenantID: number = TenantHelpers.InvalidTenantID
  public myTenantIndex: number = TenantHelpers.InvalidTenantIndex
  public teamSharePermModelOldVal: JigConst.SharePermission[] = []
  public teamSharePermissionsViewValue: JigConst.SharePermission = JigConst.SharePermission.view
  public teamSharePermissionsReshareValue: JigConst.SharePermission = JigConst.SharePermission.reshare
  public teamSharePermissionsEditValue: JigConst.SharePermission = JigConst.SharePermission.edit
  public isTeamShareConfigChange: boolean = false
  public currentPermission: TenantJigPermissions | null = null
  public newTeamPermissions: TenantJigPermissions = {
    TenantID: this.myTenantID,
    Permissions: [],
  }
  public originalJigTeamSharePermissions!: TenantJigPermissions[] | undefined

  get teamSharePermModel(): JigConst.SharePermission[] {
    return this.teamSharePermissions
  }

  set teamSharePermModel(value: JigConst.SharePermission[]) {
    this.updateTeamSharePermissions(value)
  }

  public get jigShareEvents(): any {
    return JigConst.jigShareEvents
  }

  public get jigEvents(): any {
    return JigConst.jigEvents
  }

  public get jigPermissionEvents(): any {
    return JigConst.jigPermissionEvents
  }

  public get rootJigEvents(): any {
    return JigConst.rootJigEvents
  }

  // Whether the whole team can view the Jig
  public get teamCanView(): boolean {
    return this.teamSharePermModel.includes(this.teamSharePermissionsViewValue)
  }

  // Whether the whole team can reshare the Jig
  public get teamCanReshare(): boolean {
    return this.teamSharePermModel.includes(this.teamSharePermissionsReshareValue)
  }
  public teamCanReshareCheck(jigTenantsPermission?: TenantJigPermissions[]) {
    const tenantSharePerm: TenantJigPermissions | undefined = this.getTenantSharePermission(jigTenantsPermission)

    if (tenantSharePerm == null) {
      return false
    }

    return tenantSharePerm.Permissions.includes(JigConst.SharePermission.reshare)
  }

  // Whether the whole team can edit the Jig
  public get teamCanEdit(): boolean {
    return this.teamSharePermModel.includes(this.teamSharePermissionsEditValue)
  }
  public teamCanEditCheck(jigTenantsPermission: TenantJigPermissions[] | undefined) {
    const tenantSharePerm: TenantJigPermissions | undefined = this.getTenantSharePermission(jigTenantsPermission)

    if (tenantSharePerm == null) {
      return false
    }

    return tenantSharePerm.Permissions.includes(JigConst.SharePermission.edit)
  }

  public isOwner(uid?: number): boolean {
    if (uid == null) {
      return this.jigMetadata.Uid === this.$auth0.uid
    }

    return uid === this.$auth0.uid
  }

  public canEditOrDeleteJig(jig?: JigMetadata): boolean {
    const jigData = jig == null ? this.jigMetadata : jig

    return this.hasEditPermission(jigData)
  }

  // Jig can be duplicated if:
  // 1. Current logged-in user is Jig owner, or
  // 2. Jig is set to CopyableByAnyone, or
  // 3. Jig is set to CopyableByTeam and user is part of the team, or
  // 4. Current logged-in user can edit this Jig
  // 4. Current logged-in user is a Team Admin of JigOwnerTenant, or
  // 5. Current logged-in user is a Superuser (SuperAdmin)
  public canCopyJig(jig?: JigMetadata): boolean {
    const jigData = jig == null ? this.jigMetadata : jig

    return (
      this.isOwner(jigData.Uid) ||
      jigData.CopyableBy === JigConst.JigCopyable.Anyone ||
      (jigData.OwnerTenantID === this.myTenant.ID && jigData.CopyableBy === JigConst.JigCopyable.Tenant) ||
      this.canEditOrDeleteJig() ||
      this.isTeamAdminOfJigOwnerTenant(jigData.OwnerTenantID) ||
      this.isSuperUser
    )
  }

  // User can assign 1:1 permission of a Jig if:
  // 1. Current logged-in user is Jig owner, or
  // 2. Current logged-in user is Team Manager of JigOwnerTenant
  // 3. Current logged-in user is Team Admin of JigOwnerTenant, or
  // 4. Current logged-in user is a Superuser (SuperAdmin)
  public canAssignIndividualPermission(jig?: JigMetadata): boolean {
    const jigData = jig == null ? this.jigMetadata : jig
    return (
      this.isJigOwner() ||
      this.isTeamAdminOfJigOwnerTenant(jigData.OwnerTenantID) ||
      this.isTeamManagerOfJigOwnerTenant(jigData.OwnerTenantID) ||
      this.isSuperUser
    )
  }

  public canEnableTeamReshare(jig?: JigMetadata): boolean {
    if (jig == null) {
      return this.canEditOrDeleteJig() || this.teamCanReshare
    }

    return this.canEditOrDeleteJig(jig) || this.teamCanReshareCheck(jig.Tenants)
  }

  public isJigOwner(uid?: number) {
    const jigUid = uid || this.jigMetadata.Uid
    return this.$auth0.uid === jigUid
  }

  // Jig can be edit when:
  // 1. Current logged-in user is Jig owner, or
  // 2. Current logged-in user has 1:1 edit permission for the Jig, or
  // 3. `team can edit` is turned on, or
  // 4. Current logged-in user is TeamManager of JigOwnerTenant, or
  // 5. Current logged-in user is TeamAdmin of JigOwnerTenant, or
  // 6. Current logged-in user is a SuperUser (SuperAdmin)
  private hasEditPermission(jig: JigMetadata) {
    const isCRUDEmpty =
      jig == null ||
      jig.RequestUserPermission == null ||
      jig.RequestUserPermission.CRUD == null ||
      Object.keys(jig.RequestUserPermission.CRUD).length === 0

    const teamCanEdit = jig != null ? this.teamCanEditCheck(jig.Tenants) : this.teamCanEdit

    return (
      this.isOwner(jig.Uid) ||
      (!isCRUDEmpty && jig.RequestUserPermission.CRUD.U === true) ||
      teamCanEdit ||
      this.isTeamManagerOfJigOwnerTenant(jig != null ? jig.OwnerTenantID : undefined) ||
      TenantHelpers.IsTeamAdmin(this.currentSignedInUser) ||
      this.isSuperUser
    )
  }

  public getTenantSharePermission(tenants?: TenantJigPermissions[]) {
    if (tenants == null) {
      return undefined
    }

    return tenants.find(
      (tenant: TenantJigPermissions) => tenant.Type === JigConst.JigTenantsType.share && tenant.TenantID === this.myTenant.ID
    )
  }

  // Due to historical issue we had `action` field sending
  // the value of what `origin` field should be sending.
  // We send both fields for a while for now. When data is cleaned we can safely remove `action` field.
  public eventTracking(action: string = '') {
    let trackAction = action

    if (action === '') {
      // prettier-ignore
      trackAction = this.$route.name
        ? this.$route.name
          .split(/\s/g)
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join('')
        : ''
    }

    return {
      action: trackAction,
      origin: trackAction,
    }
  }

  public isTeamManagerOfJigOwnerTenant(ownerTenantID?: number): boolean {
    return TenantHelpers.IsTeamManager(this.currentSignedInUser, this.myTenant.ID, ownerTenantID || this.jigMetadata.OwnerTenantID)
  }

  public isTeamAdminOfJigOwnerTenant(ownerTenantID?: number): boolean {
    return TenantHelpers.IsTeamAdminOfTenant(this.currentSignedInUser, this.myTenant.ID, ownerTenantID)
  }

  public updateMyTenantIndex(tenantId?: number) {
    // Jig details endpoint returns full list of Team Access permissions (fixed in EN-549), for easier access we need to record the myTenantID of matching Tenant's team access settings. Otherwise we will have to use Array.find all the time.
    if (!this.jigMetadata.Tenants || this.jigMetadata.Tenants.length === 0) {
      return
    }

    if (tenantId != null) {
      this.myTenantID = tenantId
    }

    let matchingIndex = this.jigMetadata.Tenants.findIndex(
      (tenant: any) => tenant.TenantID === this.myTenantID && tenant.Type === JigConst.JigTenantsType.share
    )

    // If current Tenant's team access permission already exists, save its index for easier access. Otherwise index should indicate the new index that new permission item can be assigned to in `.Tenants` array.
    if (matchingIndex >= 0) {
      this.myTenantIndex = matchingIndex
    } else {
      this.myTenantIndex = this.jigMetadata.Tenants.length
    }
  }

  // We only accept team share permissions values to be following combinations:
  // ['view']
  // ['view', 'sharable-readonly']
  // ['view', 'sharable-readonly', 'edit']
  public onTeamShareChanged(value: any) {
    if (!value.includes(this.teamSharePermissionsViewValue)) {
      // If no `view` is selected, remove all team permissions
      this.updateTeamSharePermissions([])
    } else if (
      !this.teamSharePermModelOldVal.includes(this.teamSharePermissionsEditValue) &&
      this.teamSharePermModel.includes(this.teamSharePermissionsEditValue) &&
      !this.teamSharePermModel.includes(this.teamSharePermissionsReshareValue)
    ) {
      // If assigning `edit` without `sharable-readonly`, append `sharable-readonly` automatically
      this.teamSharePermModel.push(this.teamSharePermissionsReshareValue)
    } else if (
      this.teamSharePermModelOldVal.includes(this.teamSharePermissionsReshareValue) &&
      this.teamSharePermModelOldVal.includes(this.teamSharePermissionsEditValue) &&
      !this.teamSharePermModel.includes(this.teamSharePermissionsReshareValue)
    ) {
      // If removing `sharable-readonly`, remove `edit` automatocally, keep only `view`
      this.updateTeamSharePermissions([this.teamSharePermissionsViewValue])
    }

    if (this.$route.name === 'Jig detail' || this.$route.name === 'Team Jigs') {
      this.$emit(this.jigEvents.onUpdateJigShareConfig, { permissionsDirty: true })
    }

    this.$emit(this.jigEvents.onUpdatingJigVisibility, {
      teamSharePermissions: this.teamSharePermModel,
      myTenantIndex: this.myTenantIndex,
      callback: (payload: JigTeamSharePermissionCallbackPayload) => {
        const { currentPermission, newTeamPermissions } = payload

        this.teamShareEventTrack(currentPermission, newTeamPermissions)
      },
    })

    this.teamSharePermModelOldVal = this.teamSharePermModel
  }

  public craftJigTeamSharingData(payload: JigVisibilityEventData) {
    this.newTeamPermissions = {
      TenantID: this.myTenantID,
      Permissions: payload.teamSharePermissions as JigConst.SharePermission[],
    }

    this.newTeamPermissions.Type = JigConst.JigTenantsType.share

    if (!this.jigMetadata.Tenants) {
      this.jigMetadata.Tenants = []
    }

    if (payload.myTenantIndex != null) {
      if (this.currentPermission == null) {
        this.currentPermission = this.jigMetadata.Tenants[payload.myTenantIndex]
          ? AppHelper.deepCopyNestedJsonSafeObject(this.jigMetadata.Tenants[payload.myTenantIndex])
          : {}
      }

      // Jig details endpoint returns full list of Team Access permissions (fixed in EN-549), this.myTenantIndex indicates which permission object to be updated. It might be an existing one or assign a new one.
      this.jigMetadata.Tenants[payload.myTenantIndex] = this.newTeamPermissions
    }
  }

  public resetTeamSharePermissions() {
    this.jigMetadata.Tenants = this.originalJigTeamSharePermissions

    this.isTeamShareConfigChange = false
    this.currentPermission = null
    this.newTeamPermissions = {
      TenantID: this.myTenantID,
      Permissions: [],
    }
    this.originalJigTeamSharePermissions = this.jigMetadata.Tenants
  }

  // Specific event tracking when team sharing is updated.
  // We only track when:
  // 1. Team view is enabled/disabled
  // 2. Team re-share is enabled/disabled
  // When team view and re-share are enabled at the same time, send both events.
  // Team share setting toggles are disabled in multi-jig share modal,
  // hence no special treatments need to be done for multi-share situation.
  public teamShareEventTrack(currentPermission: TenantJigPermissions | null, newPermission: TenantJigPermissions) {
    let teamShareViewEnabled = false
    let teamShareReshareEnabled = false
    let teamShareViewDisabled = false
    let teamShareReshareDisabled = false
    let teamShareEditEnabled = false
    let teamShareEditDisabled = false
    // A brand new Jig might have no Tenants details at all, so might be undefined.
    // It also might have a Permissions but content is `undefined` or `null` instead of an empty string.
    // So we need to handle both situations to compare the values.
    // When team permission has been changed, we check status and decide what evebt tracking we need to push.
    const teamPermissionDontExist = !currentPermission || !currentPermission.Permissions

    if (
      (teamPermissionDontExist && newPermission.Permissions.length) ||
      (currentPermission &&
        currentPermission.Permissions &&
        !isEqual(currentPermission.Permissions.sort(), newPermission.Permissions.sort()))
    ) {
      teamShareViewEnabled =
        this.teamCanView && (teamPermissionDontExist || !currentPermission.Permissions.includes(this.teamSharePermissionsViewValue))
      teamShareReshareEnabled =
        this.teamCanReshare && (teamPermissionDontExist || !currentPermission.Permissions.includes(this.teamSharePermissionsReshareValue))
      teamShareEditEnabled =
        this.teamCanEdit && (teamPermissionDontExist || !currentPermission.Permissions.includes(this.teamSharePermissionsEditValue))
      teamShareViewDisabled =
        !this.teamCanView && !teamPermissionDontExist && currentPermission.Permissions.includes(this.teamSharePermissionsViewValue)
      teamShareReshareDisabled =
        !this.teamCanReshare && !teamPermissionDontExist && currentPermission.Permissions.includes(this.teamSharePermissionsReshareValue)
      teamShareEditDisabled =
        !this.teamCanEdit && !teamPermissionDontExist && currentPermission.Permissions.includes(this.teamSharePermissionsEditValue)
    }

    JigTeamShareTracking.segmentEventTrackForJigTeamSharingSet(
      teamShareViewEnabled,
      teamShareViewDisabled,
      teamShareReshareEnabled,
      teamShareReshareDisabled,
      teamShareEditEnabled,
      teamShareEditDisabled,
      this.eventTracking,
      this.jigMetadata
    )
  }
}
