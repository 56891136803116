import { Tenant } from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component({})
export default class MaxJigsCheck extends Vue {
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('maxJigCountValue', { namespace: Namespace.Jig })
  public maxJigCountValue!: number

  @Action('loadJigCount', { namespace: Namespace.Jig })
  public loadJigCount: any

  public async IsMaxJigsLimitReached() {
    // Make sure get latest value to avoid error.
    await this.loadJigCount({ mutationName: 'updateMaxJigCountValue' })

    return this.myTenant.Subscription.MaxJigs !== 0 && this.maxJigCountValue >= this.myTenant.Subscription.MaxJigs
  }

  public upgradePlan(myTenantID: number) {
    segmentEventTracking('TenantMaxJigLimit_UpgradePanelClicked', {
      tenantName: this.myTenant.Name,
      tenantId: myTenantID,
    })

    this.$router.push({
      path: '/subscription',
      query: {
        stripeSuccess: this.$route.path,
      },
    })
  }
}
