import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'

import Meta from 'vue-meta'

import { authenticationGuard } from '@/auth/authentication-guard'

Vue.use(Router)

const webDomain = process.env.VUE_APP_WEB_DOMAIN || ''
const dashboardEnv = process.env.VUE_APP_DASHBOARD_ENV || ''

const paths = [
  {
    path: '/user-profile',
    name: 'User profile',
    view: 'UserProfile',
    loginRequired: true,
  },
  {
    path: '/overview',
    redirect: '/my-jigs',
  },
  {
    path: '/my-jigs',
    name: 'My Jigs',
    view: 'JigsListing',
    loginRequired: true,
  },
  {
    path: '/team-jigs',
    name: 'Team Jigs',
    view: 'JigsListing',
    loginRequired: true,
    teamJigView: true,
  },
  {
    path: '/jig-detail/:id',
    name: 'Jig detail',
    view: 'JigDetail',
    loginRequired: true,
  },
  {
    path: '/library',
    name: 'Models library',
    view: 'Library',
    loginRequired: true,
  },
  {
    path: '/library-entry/:id',
    name: 'Library entry',
    view: 'LibraryEntry',
    loginRequired: true,
  },
  {
    path: '/library-categories',
    name: 'Library categories',
    view: 'LibraryCategories',
    loginRequired: true,
  },
  {
    path: '/tenants',
    name: 'All teams',
    view: 'Tenants',
    loginRequired: true,
  },
  {
    path: '/tenant',
    name: 'Team branding',
    view: 'Tenant',
    loginRequired: true,
  },
  {
    path: '/tenant-users',
    name: 'My team',
    view: 'TenantUsers',
    loginRequired: true,
  },
  {
    path: '/subscription',
    name: 'Subscription',
    view: 'Subscription',
    loginRequired: true,
  },
  {
    path: '/callback',
    name: 'Callback',
    view: 'Callback',
    loginRequired: false,
  },
  {
    path: '/pre-login',
    name: 'Pre login',
    view: 'PreLogin',
    loginRequired: false,
  },
  {
    path: '/oops',
    name: 'Oops',
    view: 'Errors/Oops',
    loginRequired: false,
  },
  {
    path: '/no-tenant',
    name: 'No Tenant',
    view: 'Errors/NoTenant',
    loginRequired: true,
  },
  {
    path: '/dashboard-restricted',
    name: 'Dashboard restricted',
    view: 'Errors/DashboardRestricted',
    loginRequired: true,
  },
  {
    path: '/not-found',
    name: 'Not found',
    view: 'Errors/NotFound',
    loginRequired: false,
  },
  {
    path: '/buy',
    name: 'Buy',
    loginRequired: false,
    redirectToExternalUrl: `//buy.${webDomain.substring(8)}`,
  },
  {
    path: '/command',
    name: 'Command center',
    view: 'CommandCentre',
    loginRequired: true,
  },
  {
    path: '/download',
    name: 'Download app',
    loginRequired: false,
    redirectToExternalUrl: `https://${
      dashboardEnv === 'production' ? 'www.jig.com' : 'jigspace-new-dacctrsx-85l-da111a9a314e9.webflow.io'
    }/download`,
  },
  {
    path: '/welcome',
    name: 'Welcome',
    view: 'Welcome',
    loginRequired: true,
  },
  {
    path: '/versions',
    name: 'Versions',
    view: 'Versions',
    loginRequired: true,
  },
  // Keep Dashboard.vue here commented out.
  // It can be uncommented to quickly test a pure setup js+vue+vuetify2.2 page
  // {
  //   path: '/dashboard',
  //   view: 'Dashboard',
  // },
]

function route(path: string, view: string, name: string | undefined): RouteConfig {
  return {
    name: name || view,
    path,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: (resolve: ((value: any) => any) | null | undefined) =>
      import(/* webpackChunkName: "routerview" */ `@/views/${view}.vue`).then(resolve),
  }
}

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  routes: paths
    .map((path: any) => {
      // Do not run route() function for route that needs to be redirected to an external url. Otherwise component resolve will raise error.
      if (path.redirect) {
        return path
      }

      const r = path.redirectToExternalUrl ? path : route(path.path, path.view, path.name)
      r.meta = {
        loginRequired: path.loginRequired,
        redirectToExternalUrl: path.redirectToExternalUrl,
        teamJigView: path.teamJigView,
      }

      r.beforeEnter = path.beforeEnter
      return r
    })
    .concat([
      { path: '*', redirect: '/not-found' }, // If users come to any non-configured path, we take them to /not-found
      { path: '/', redirect: '/pre-login' }, // If users come to the root, we ask take them to /pre-login
    ]),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.name !== 'Callback' && to.name !== 'Oops' && to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (location.pathname !== '/' && location.pathname.endsWith('/')) {
    // Webpack does some strange things not linking styles and images if the path has a trailing slash
    // So we force all routes to go to the non trailing slash version.
    // If you're routing to a path in this app, try to use the non trailing slash version which will be faster.
    location.pathname = location.pathname.substring(0, location.pathname.length - 1)
  }

  if (to.meta !== undefined && to.meta.redirectToExternalUrl) {
    // Redirect to desired external url
    window.location.href = to.meta.redirectToExternalUrl

    return
  }

  authenticationGuard(to, from, next)
})

Vue.use(Meta)

export default router
