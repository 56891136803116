/**
 * A Vue mixin that provides functionality for filtering and retrieving user information within the context of Jigs.
 * At this stage this mixin is designed to be mainly used in Team Jigs filters component.
 *
 * It leverages the Vuex store to access the `tenantUsers` state from the `App` module and provides a method
 * to retrieve user details by their unique identifier (UID). The `getUser` method uses the `AppHelper.getUser`
 * utility to fetch and format user information.
 */
import { TenantUser } from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({})
export default class JigsFiltersMixin extends Vue {
  @State('tenantUsers', { namespace: Namespace.App })
  public tenantUsers!: TenantUser[]

  public removedUsersList: TenantUser[] = []
  public selectedUsers: TenantUser[] = []

  @Watch('tenantUsers')
  private async onTenantUsersChanged(value: TenantUser[]) {
    this.selectedUsers = [...this.allJigOwnersList]
    this.$emit('on-selected-user-change', this.selectedUsers)
  }

  protected mounted() {
    this.selectedUsers = [...this.allJigOwnersList]
  }

  public get allJigOwnersList(): TenantUser[] {
    if (this.removedUsersList == null || this.removedUsersList.length === 0) {
      return this.tenantUsers
    }

    return this.tenantUsers.concat(this.removedUsersList)
  }

  /**
   * Retrieves user details for a given user ID (UID) from the list of tenant users.
   *
   * @param {number} uid - The unique identifier (UID) of the user to retrieve.
   * @returns {Object} An object containing the user's email, name, and a formatted tooltip display.
   */
  public getUser(uid: number) {
    return AppHelper.getUser(uid, this.tenantUsers)
  }

  public selectedUserIndex(user: TenantUser, sourceUsers?: TenantUser[]) {
    const users = sourceUsers == null ? this.selectedUsers : sourceUsers

    return users.findIndex((u: TenantUser) => u.Uid === user.Uid)
  }

  public resetSelectedUsers() {
    this.selectedUsers = [...this.allJigOwnersList]
  }
}
