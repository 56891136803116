import LockPromptYesNo from '@/components/modals/LockPromptYesNo.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import { JigConst, MueConst } from '@/constants'
import { TenantUser } from '@/store/modules/app/types'
import { JigMetadata, LockRequestPayload } from '@/store/modules/jig/types'
import { Namespace } from '@/store/types'
import { eventBus } from '@/utils/eventBus'
import { JigLockSharedFunction } from '@/utils/mixin-shared-function'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Mutation, State } from 'vuex-class'

/**
 * The JigLock mixin class manages the Jig Lock System
 * It handles creating and releasing locks and shows a modal popup that notifies users
 * when a lock already exists.
 * It is designed for reuse across the application, ensuring consistent permission logic.
 *
 * Important components:
 * - LockPromptYesNo: A modal popup displayed when the createLock response status is 423 (lock exists),
 *   allowing the user to notify the lock owner.
 *
 * Computed Properties:
 * - JigLockOwnerName: Retrieves the name of the current lock owner.
 * - JigLockUIDDisplay: Provides a string representation of the lock owner's UID (e.g., `UID: 13412` or `N/A`).
 * - IsJigLockActive: Checks if the Jig currently has an active lock.
 *
 * Methods:
 * - IsJigLockOwnedByOthers: Determines if the Jig lock is owned by another user.
 * - RequestNewLock(callback?: Function): Requests a new lock and handles responses,
 *   including showing the notification modal if the lock already exists.
 * - ShowLockNotifyModal(): Displays the notification modal.
 *
 * The lock system is required when:
 * 1. Any changes to JigMetadata data (e.g., thumbnail upload, visibility change, team share change, Jig name change etc.)
 * 2. Deleting a Jig (releaseLock is not required after a Jig is deleted).
 *
 * Full lock system progress should be as follows:
 * 1. Request a new lock.
 *    1.1 If unable to obtain a lock:
 *        1.1.1 Lock already exists:
 *              - Show the notify user modal (LockPromptYesNo)
 *              - End process
 *        1.1.2 Any other error:
 *              - Return an error message
 *              - End process
 *    1.2 Lock obtained successfully:
 *        - Proceed to step 2: make a JigMetadata change request
 * 2. Make a JigMetadata change request or delete Jig request.
 *    2.1 If the request fails:
 *        - Proceed to step 3: release the lock
 *    2.2 If the request succeeds:
 *        - Proceed to step 3: release the lock (not required when a Jig is deleted successful)
 * 3. Release the lock.
 *    3.1 If release fails:
 *        - Return an error message
 *        - End process
 *    3.2 If release succeeds:
 *        - End process
 */
@Component({
  components: {
    LockPromptYesNo,
    PromptYesNo,
  },
})
export default class JigLock extends Vue {
  @State('jigMetadata', { namespace: Namespace.Jig })
  public jigMetadata!: JigMetadata
  @State('lockPayload', { namespace: Namespace.Jig })
  public lockPayload!: LockRequestPayload
  @State('tenantUsers', { namespace: Namespace.App })
  public tenantUsers!: TenantUser[]

  @Mutation('updateLockPayloadJigId', { namespace: Namespace.Jig })
  public updateLockPayloadJigId: any

  @Action('createLock', { namespace: Namespace.Jig }) public createLock: any
  @Action('releaseLock', { namespace: Namespace.Jig }) public releaseLock: any
  @Action('notifyAwaitingLock', { namespace: Namespace.Jig }) public notifyAwaitingLock: any

  public myTenantID: number = TenantHelpers.InvalidTenantID
  public isProcessing: boolean = false
  public isProcessingPromptYesNo: boolean = false

  public $refs!: Vue['$refs'] & {
    jigLockNotifyPromptYesNo?: LockPromptYesNo | PromptYesNo
  }

  public get JigLockOwnerName(): string {
    return JigLockSharedFunction.jigLockOwnerName(this.jigMetadata, this.tenantUsers)
  }

  public get JigLockUIDDisplay(): string {
    return JigLockSharedFunction.jigLockUIDDisplay(this.jigMetadata)
  }

  public get IsJigLockActive(): boolean {
    return JigLockSharedFunction.isJigLockActive(this.jigMetadata)
  }

  public IsJigLockOwnedByOthers(jig?: JigMetadata): boolean {
    const jigData = jig == null ? this.jigMetadata : jig
    return JigLockSharedFunction.isJigLockOwnedByOthers(jigData, this.$auth0.uid)
  }

  public async RequestNewLock(callback?: Function) {
    const lockResponse = await this.createLock(this.lockPayload)

    if (!lockResponse.isSuccessful) {
      if (lockResponse.status === MueConst.LockResponseCode.lockAlreadyExists) {
        if (this.$refs.jigLockNotifyPromptYesNo != null) {
          this.$refs.jigLockNotifyPromptYesNo.ShowPrompt()
        } else {
          eventBus.$emit(JigConst.rootJigEvents.onLockNotifyRequired)
        }
      }

      this.isProcessing = false
      this.isProcessingPromptYesNo = false

      return false
    }

    if (typeof callback === 'function' && callback.constructor.name === 'AsyncFunction') {
      await callback()
    } else if (typeof callback === 'function') {
      callback()
    }

    return true
  }

  public ShowLockNotifyModal() {
    if (this.$refs.jigLockNotifyPromptYesNo != null) {
      this.$refs.jigLockNotifyPromptYesNo.ShowPrompt()
    }
  }
}
